import React, { useState, useEffect, useCallback, useReducer } from "react";
import Amplify, { Auth, Storage } from "aws-amplify";
import { PhotoPicker, S3Album, S3Image, TextPicker } from "aws-amplify-react";

// Amplify のPhotoPickerを使用して、png fileを読込 chunk iTxtのOpen Badge Assertion JSON を取得するために必要なnpm module
import pngitxt from "png-itxt";
import toStream from "buffer-to-stream";
import dataUriToBuffer from "data-uri-to-buffer";
import stream from "stream";
import request from "request";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";
import CardFooter from "components/Card/CardFooter.js";
import AccessTime from "@material-ui/icons/AccessTime";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardimage: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
  }
};

const useStyles = makeStyles(styles);

//--------------------------------
//Badge Image Folder名
const badgeimagefolder = "badgeimage/";
//Badge Image Folder名
const badgeimageEditfolder = "badgeimage-edit/";
//Badge JSON Folder名
const badgejsonfolder = "badgejson/";
let jsondata;
//PNG画像ファイルにiTxtを書き込むために、pngitxtで使用するstreamからpipe経由で書き込むための方法
var segments = [];
var fileinfo;
var writable = new stream.Writable({
  write: function(chunk, encoding, next) {
    // console.log(chunk);
    // console.log("chunk.byteLength: ", chunk.byteLength);
    segments.push(chunk);
    next();
  }
});
writable.on("finish", () => {
  console.log("finish arrayBuffer");
  var sumLength = 0;
  for (var i = 0; i < segments.length; ++i) {
    sumLength += segments[i].byteLength;
  }
  var whole = new Uint8Array(sumLength);
  var pos = 0;
  for (i = 0; i < segments.length; ++i) {
    whole.set(new Uint8Array(segments[i]), pos);
    pos += segments[i].byteLength;
  }
  console.log(whole);
  //byteArrayをBlobに変換してStorage.putでS3に保存
  var blob = new Blob([whole]);
  const newfilename =
    fileinfo.name.split(".")[0] + "-edit." + fileinfo.name.split(".")[1];
  console.log("newfilename: ", newfilename);
  Storage.put(badgeimageEditfolder + newfilename, blob, {
    level: "private",
    contentType: fileinfo.type
  })
    .then(result => console.log(result))
    .catch(err => console.log(err));
  const newjsonfilename = fileinfo.name.split(".")[0] + "-edit.json";
  console.log("newjsonfilename: ", newjsonfilename);
  Storage.put(badgejsonfolder + newjsonfilename, jsondata, {
    level: "protected",
    // contentType: 'text/json'
    contentType: "application/json"
  })
    .then(result => console.log(result))
    .catch(err => console.log(err));
});

function callbackPng(err, data) {
  console.log("pngitxt.get");
  if (!err && data) {
    console.log("openbadges True");
    // console.log(JSON.stringify(data));
    const { value } = data;
    jsondata = value;
    console.log("openbadges value");
    console.log(JSON.stringify(value, null, "\t"));
  } else {
    console.log("openbadges False", err);
  }
}

const initialBadgeList = [];
const reducerListFile = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};

export default function Page1() {
  const classes = useStyles();
  const [pickfilekey, setPickFileKey] = useState();
  // const [jeditorkey, setJsonEditorKey] = useState(1);
  // const { stateJson, onChangeJson } = useJson();
  const [listfiles, dispatchStorageList] = useReducer(
    reducerListFile,
    initialBadgeList
  );
  const onSetPickFileKey = useCallback(key => {
    setPickFileKey(prev => key);
  }, []);

  const onSetListFiles = () => {
    // Using an IIFE
    (async function anyNameFunction() {
      const listfilesall = await Storage.list(badgeimagefolder, {
        level: "private"
      });
      console.log(listfilesall);
      dispatchStorageList({ type: "FETCH_SUCCESS", payload: listfilesall });
    })();
  };
  useEffect(() => {
    onSetListFiles();
  }, []);

  return (
    <GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>日本語Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} />
                  日本語 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                Material Dashboard Heading
              </h4>
              <p className={classes.cardCategoryWhite}>
                Created using Roboto Font Family
              </p>
            </CardHeader>
            <CardBody>
              <div className={classes.typo}>
                <div className={classes.note}>Header 1</div>
                <h1>The Life of Material Dashboard</h1>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <PhotoPicker
              // theme={AmplifyTheme}
              preview
              onPick={data => {
                console.log(JSON.stringify(data));
                const { file } = data;
                fileinfo = file;
                Storage.put(badgeimagefolder + file.name, file, {
                  level: "private",
                  contentType: file.type
                })
                  .then(result => {
                    console.log(result);
                    // onSetListFilesLength();
                    onSetListFiles();
                  })
                  .catch(err => console.log(err));
              }}
              onLoad={dataURL => {
                //dataURLの形式データをpngitxtにpipeで通常のファイルデータ形式(buffer形式)で渡す必要がある
                //そのため、dataURLからbufferに変換して、bufferをstreamに変換してpipeで渡す
                const buffer = dataUriToBuffer(dataURL);
                console.log("buffer");
                console.log(buffer.byteLength);
                // PNG iTxt openbadges jsonの読込
                toStream(buffer).pipe(pngitxt.get("openbadges", callbackPng));
                // PNG iTxt openbadges jsonの書込
                toStream(buffer)
                  .pipe(
                    pngitxt.set({ keyword: "openbadges", value: "null" }, true)
                  )
                  .pipe(writable);
              }}
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <p>
              --------------------Display Badge
              Image-------------------------------
            </p>
            {pickfilekey && (
              <S3Image
                level="private"
                imgKey={pickfilekey}
                onLoad={url => {
                  console.log("S3Image url");
                  console.log(url);
                  if (url.indexOf("http") > -1) {
                    // PNGをgetするためencoding: nullが必要
                    request(
                      url,
                      { encoding: null },
                      (error, response, body) => {
                        // エラーチェック
                        if (error !== null) {
                          console.error("error:", error);
                          return false;
                        }
                        // レスポンスコードとHTMLを表示
                        console.log(
                          "statusCode:",
                          response && response.statusCode
                        );
                        // Bufferに変換して読込
                        const buffer = new Buffer.from(body);
                        toStream(buffer).pipe(
                          pngitxt.get("openbadges", callbackPng)
                        );
                      }
                    );
                  }
                }}
              />
            )}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <p>
              --------------------My Badge
              Collection-------------------------------
            </p>
            <S3Album
              level="private"
              path={badgeimagefolder}
              //filter={(item) => /png/i.test(item.key)}
              // picker fileToKey={fileToKey}
              onClickItem={(item, selected_items) => {
                console.log(item);
                onSetPickFileKey(item.key);
              }}
              // onPick={console.log("onPick")}
              key={listfiles.length}
              onLoad={console.log("S3Album.onLoad")}
              style={{ display: "inline-block", paddingRight: "5px" }}

              // theme={{
              //   photoImg: {
              //     width: "20%",
              //     objectFit: "cover",
              //     borderRadius: "50%"

              //     // hover: {
              //     //   WebkitFilter: "grayscale(100%);",
              //     //   filter: "grayscale(100%)"
              //     // }
              //   }
              // }}
            />
          </Card>
        </GridItem>
      </GridContainer>
      <p>
        --------------------My Badge Collection 2-------------------------------
      </p>
      <GridContainer>
        {/* <Card> */}
        {/* <div> */}
        {listfiles.map((f, i) => {
          console.log("listfiles.map: ", i, f.key);
          return (
            <GridItem
              xs={12}
              sm={6}
              md={3}
              // className={classes.root}
              key={i}
              // style={{ height: 80, width: 80 }}
            >
              <Card>
                <S3Image
                  theme={{
                    photoImg: {
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "50%"

                      // hover: {
                      //   WebkitFilter: "grayscale(100%);",
                      //   filter: "grayscale(100%)"
                      // }
                    }
                  }}
                  // style={{ display: "inline-block", paddingRight: "5px" }}
                  level="private"
                  imgKey={f.key}
                />
              </Card>
            </GridItem>
          );
        })}
        {/* </div> */}
        {/* </Card> */}
      </GridContainer>
    </GridContainer>
  );
}
