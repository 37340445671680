import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

const styles = {
  typo: {
    paddingLeft: "5%",
    marginBottom: "10px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function TypographyPage(props) {
  const classes = useStyles();
  return (
    // <GridContainer>
    //   <GridContainer>
    //   <GridItem >
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          オープンバッジ・ハンズオン　資料
        </h4>
        <p className={classes.cardCategoryWhite}>
          ドキュメント , ツール , データ
        </p>
      </CardHeader>
      <CardBody>
        <Typography variant="headline" component="h3">
          本日説明資料(PDF)
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          <a
            href="http://s3.aws.imsjapan.org/obv2hansonslides20200910.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            本日説明資料(PDF)
          </a>
        </Typography>
      </CardBody>
      <CardBody>

      <Typography variant="headline" component="h3">
              サンプルバッジ: IMS Global Sample Open Badge (PNG)
            </Typography>
            {/* <Typography component="p" className={classes.paragraph}>
              <a
                href="http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png
              </a>
            </Typography> */}
            {/* <br /> */}
            <h4>
            {/* <Typography component="p" className={classes.paragraph}> */}
              <a
                href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(PNG) ===
                https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png
              </a>
            {/* </Typography> */}
            </h4>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(SVG) ===
                https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(JSON) ===
                https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA
              </a>
            </Typography>
            <br />
            <Typography component="p" className={classes.paragraph}>
              Expected Profile JSON (optional) 取得者のeメール : ' {"{"}
              "email":"conformance@imsglobal.org"{"}"} '
            </Typography>
      </CardBody>
      <CardBody>
        <Typography variant="headline" component="h3">
          IMS GLOBAL 技術標準仕様
        </Typography>

        <Typography component="p" className={classes.paragraph}>
          Open Badges v2.0 IMS Final Release：
          <a
            href="https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/index.html
          </a>
        </Typography>

        <Typography component="p" className={classes.paragraph}>
        Open Badges 2.0 Implementation Guide：
          <a
            href="http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/impl/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/impl/index.html
          </a>
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          Open Badges 2.0 Certification Guide：
          <a
            href="http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/cert/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/cert/index.html
          </a>
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          Open Badges 2.0 Baking Guide：
          <a
            href="http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/baking/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/baking/index.html
          </a>
        </Typography>   
        <Typography component="p" className={classes.paragraph}>
          Open Badges Examples：
          <a
            href="https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/examples/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/examples/index.html
          </a>
        </Typography>    
        <br />
        <Typography variant="headline" component="h3">
          IMS GLOBAL Git Hub Site
        </Typography>
        <Typography component="p" className={classes.paragraph}>
        IMS Global Learning Consortium Repository ：
          <a
            href="https://github.com/IMSGlobal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/IMSGlobal/
          </a>
        </Typography>
        {/* <br />       
        <Typography variant="headline" component="h3">
          ツール紹介
        </Typography>

        <Typography component="p" className={classes.paragraph}>
          <a
            href="https://editor.method.ac/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SVG Editor: method.
          </a>
        </Typography> */}
      </CardBody>
    </Card>
    //     </GridItem>
    //   </GridContainer>
    // </GridContainer>
  );
}
