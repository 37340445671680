import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import Input from '@material-ui/core/Input';
// import FormData from 'form-data';

// import { API } from 'aws-amplify';

import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";

import assertionSchema from "assets/openbadgeSchema/AssertionSchemaOBV2.json";
import issuerSchema from "assets/openbadgeSchema/IssuerSchemaOBV2.json";
import BadgeClassSchema from "assets/openbadgeSchema/BadgeClassSchemaOBV2.json";
const ajv = new Ajv({ allErrors: true, verbose: true });
const modes = ["tree", "code"];
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

// function Previews(props) {
//   const [files, setFiles] = useState([]);
//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/*",
//     onDrop: (acceptedFiles) => {
//       setFiles(
//         acceptedFiles.map((file) =>
//           Object.assign(file, {
//             preview: URL.createObjectURL(file),
//           })
//         )
//       );
//     },
//   });

//   const thumbs = files.map((file) => (
//     <div style={thumb} key={file.name}>
//       <div style={thumbInner}>
//         <img src={file.preview} style={img} />
//       </div>
//     </div>
//   ));

//   useEffect(
//     () => () => {
//       // Make sure to revoke the data uris to avoid memory leaks
//       files.forEach((file) => URL.revokeObjectURL(file.preview));
//     },
//     [files]
//   );

//   return (
//     <section className="container">
//       <div {...getRootProps({ className: "dropzone" })}>
//         <input {...getInputProps()} />
//         <p>Drag 'n' drop some files here, or click to select files</p>
//       </div>
//       <aside style={thumbsContainer}>{thumbs}</aside>
//     </section>
//   );
// }

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const fileUpload = async (uploadPath, uploadfile) => {
    const formData = new FormData();
    formData.append('body-json', uploadfile);
    formData.append('assertion', "https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A");
    formData.append('recipient_profile', "{\"email\": \"t-hata@digital-knowledge.co.jp\"}");
    const headers = {
         "content-type": "multipart/form-data"
        };
    return await axios.post(uploadPath, formData,{headers});
    // return await axios.post(uploadPath, {
    //     assertion: 'https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A',
    //     recipient_profile: '{\"email\": \"t-hata@digital-knowledge.co.jp\"}'
    //   },{headers});
};

// const fileUpload = async (uploadPath, uploadfile) => {
//     const formData = new FormData();
//     // const body
//     formData.append('assertion', uploadfile);
//     // formData.append('recipient_profile', "{\"email\": \"t-hata@digital-knowledge.co.jp\"}");
    
//     // const headers = { "content-type": "multipart/form-data" };
//     return await axios.post(uploadPath, formData);
// };

// const handleChange = async (uploadfile,emailValue) => {
//     console.log("handleChange");
//     const uploadApiPath = "https://2nx9i2outa.execute-api.us-east-1.amazonaws.com/default/uploadMultiData";
//     const formData = new FormData();
//     formData.append('body-json', uploadfile);
//     // formData.append('assertion', "https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A");
//     // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
//     // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
//     console.log("email: "+ emailValue);
//     // const params = `{'recipient_profile': '{"email": "${emailValue}"}'}`;
//     const params = JSON.parse(`{"recipient_profile": {"email": "${emailValue}"}}`);
//     // const params = {'recipient_profile': '{"email": "t-hata@digital-knowledge.co.jp"}'};
//     console.log("params: "+ JSON.stringify(params));
//     const headers = {
//          "content-type": "multipart/form-data"
//         };
//     const returnMessage =  await axios.post(uploadApiPath, formData,{headers,params});
//     // console.log("returnMessage: ",returnMessage);
//     // console.log("returnMessage: ",JSON.stringify(returnMessage));
//     const { data } = returnMessage;
//     if (JSON.stringify(data).indexOf("errorMessage") > -1 &&  JSON.stringify(data).indexOf("errorType") > -1 ) {
//       console.log("Error: errorMessage , errorType ==> ", data["errorMessage"] + " : " + data["errorType"]);
//       return
//     }
//     const { is_valid } = data;
//     const { body } = data;
//     const resultJson = JSON.parse(body);
//     const { graph } = resultJson;
//     const { input } = resultJson;
//     const { report } = resultJson;
//     console.log("is_valid: ",is_valid);
//     setOBV2Metadata(resultJson);
//     // console.log("graph: ",graph);

//     var Assertion,BadgeClass,Issuer
//     graph.forEach(function(item,index){
//       console.log(index + " : " + item["type"])
//       console.log(`Badge Json: ${item["type"]}.`);
//       switch (item["type"]) {
//         case 'Assertion':
//           Assertion = item;
//           break;
//         case 'BadgeClass':
//           BadgeClass = item;
//           break;
//         case 'Issuer':
//           Issuer = item;
//           break;
//         default:
//           console.log(`No Badge Json`);
//       }
//     })

//     console.log("Assertion: ",JSON.stringify(Assertion,null,2));
//     console.log("BadgeClass: ",JSON.stringify(BadgeClass,null,2));
//     console.log("Issuer: ",JSON.stringify(Issuer,null,2));
//     console.log("input: ",JSON.stringify(input,null,2));
//     console.log("report: ",JSON.stringify(report,null,2));
//     // console.log("Issuer: ",graph[2]);

//   };

const useStyles = makeStyles(styles);
export default function Page8() {
  const classes = useStyles();
  const [obv2metadata, setOBV2Metadata] = useState({});
  const [AssertionJson, setAssertionJson] = useState({});
  const [BadgeClassJson, setBadgeClassJson] = useState({});
  const [IssuerJson, setIssuerJson] = useState({});
  const [emailValue,   setInput]   = useState('');
  const [files, setFiles] = useState([]);

  const handleChange = async (uploadfile,emailValue) => {
    console.log("handleChange");
    const uploadApiPath = "https://2nx9i2outa.execute-api.us-east-1.amazonaws.com/default/uploadMultiData";
    // const uploadApiPath = "https://openbadgesvalidator.imsglobal.org/results";
    const formData = new FormData();
    formData.append('body-json', uploadfile);
    // formData.append('assertion', "https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A");
    // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
    // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
    console.log("email: "+ emailValue);
    // const params = `{'recipient_profile': '{"email": "${emailValue}"}'}`;
    const params = JSON.parse(`{"recipient_profile": {"email": "${emailValue}"}}`);
    // const params = {'recipient_profile': '{"email": "t-hata@digital-knowledge.co.jp"}'};
    console.log("params: "+ JSON.stringify(params));
    const headers = {
         "content-type": "multipart/form-data"
        };
    const returnMessage =  await axios.post(uploadApiPath, formData,{headers,params});
    // console.log("returnMessage: ",returnMessage);
    // console.log("returnMessage: ",JSON.stringify(returnMessage));
    const { data } = returnMessage;
    if (JSON.stringify(data).indexOf("errorMessage") > -1 &&  JSON.stringify(data).indexOf("errorType") > -1 ) {
      console.log("Error: errorMessage , errorType ==> ", data["errorMessage"] + " : " + data["errorType"]);
      return
    }
    const { is_valid } = data;
    const { body } = data;
    const resultJson = JSON.parse(body);
    const { graph } = resultJson;
    const { input } = resultJson;
    const { report } = resultJson;
    console.log("is_valid: ",is_valid);
    setOBV2Metadata(resultJson);
    // console.log("graph: ",graph);

    var Assertion,BadgeClass,Issuer
    graph.forEach(function(item,index){
      console.log(index + " : " + item["type"])
      console.log(`Badge Json: ${item["type"]}.`);
      switch (item["type"]) {
        case 'Assertion':
          Assertion = item;
          setAssertionJson(Assertion);
          break;
        case 'BadgeClass':
          BadgeClass = item;
          setBadgeClassJson(BadgeClass);
          break;
        case 'Issuer':
          Issuer = item;
          setIssuerJson(Issuer);
          break;
        default:
          console.log(`No Badge Json`);
      }
    })

    console.log("Assertion: ",JSON.stringify(Assertion,null,2));
    console.log("BadgeClass: ",JSON.stringify(BadgeClass,null,2));
    console.log("Issuer: ",JSON.stringify(Issuer,null,2));
    console.log("input: ",JSON.stringify(input,null,2));
    console.log("report: ",JSON.stringify(report,null,2));
    // console.log("Issuer: ",graph[2]);

  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, .json',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  const handleChangeText = (e) => {
    console.log("handleChangeText:",e.target.value)
    setInput(e.target.value);
  };
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleChangeJsonEditor = () => {
    console.log("test");
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
            react-dropzone
            </h4>
            <p className={classes.cardCategoryWhite}>
            react-dropzone
            </p>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                handleChange(files[0],emailValue);
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
          <CustomInput
                    labelText="email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{ onChange: handleChangeText}}
                  />
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
            </section>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Verify Results
            </h4>
            <p className={classes.cardCategoryWhite}>
              Graph: Assertion, BadgeClass, Issuer :: report- Results
            </p>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={obv2metadata}
              modes={modes}
              // onChange={handleChange}
              // ajv={ajv}
              // schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h3>Issuer.json</h3>
            <JSONEditorReact
              json={IssuerJson}
              modes={modes}
              onChange={handleChangeJsonEditor}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
          <h3>BadgeClass.json</h3>
            <JSONEditorReact
              json={BadgeClassJson}
              modes={modes}
              onChange={handleChangeJsonEditor}
              ajv={ajv}
              schema={BadgeClassSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
          <h3>Assertion.json</h3>
            <JSONEditorReact
              json={AssertionJson}
              modes={modes}
              onChange={handleChangeJsonEditor}
              ajv={ajv}
              schema={assertionSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
