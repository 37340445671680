import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Board from "components/Board/Board.jsx";
// import Board from './src/board/board';
import {
  authorQuoteMap,
  generateQuoteMap
} from "assets/reactBeautifulDnD/data.js";

// const data = {
//   medium: generateQuoteMap(100),
//   large: generateQuoteMap(500),
// };

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

export default function MediaCard2() {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Display Badge Info</h4>
      </CardHeader>
      <CardBody>
        <Board initial={authorQuoteMap} isCombineEnabled useClone />
      </CardBody>
    </Card>
  );
}
