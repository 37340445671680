import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import localize from "ajv-i18n";
import assertionSchema from "assets/openbadgeSchema/AssertionSchemaOBV2.json";
import issuerSchema from "assets/openbadgeSchema/IssuerSchemaOBV2.json";
import BadgeClassSchema from "assets/openbadgeSchema/BadgeClassSchemaOBV2.json";
// const assertionSchema = JSON.parse(assertionSchemaFile);
// console.log("assertionSchema: ", JSON.stringify(assertionSchema));
// console.log("issuerSchema: ", JSON.stringify(issuerSchema));
// console.log("BadgeClassSchema: ", JSON.stringify(BadgeClassSchema));
const ajv = new Ajv({ allErrors: true, verbose: true });
// ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-07.json"));
// const validate = ajv.getSchema("https://w3id.org/openbadges/v2");

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// var IssuerData = {};

// var BadgeClassData = {};

// const AssertionData = {};

const modes = [ "code", "tree", "form"];
// const modes = ["tree", "form", "view", "code", "text"];


// const handleChangeAssertion = () => {
//   console.log(JSON.stringify(AssertionData));
// };

const jsonldValidate = (schema, jsondata) => {
  console.log("schema: ", schema);
  var valid;
  switch (schema) {
    case "Issuer":
      valid = ajv.validate(issuerSchema, jsondata);
      break;
    case "BadgeClass":
      valid = ajv.validate(BadgeClassSchema, jsondata);
      break;
    case "Assertion":
      valid = ajv.validate(assertionSchema, jsondata);
      break;
    default:
      break;
  }
  if (!valid) {
    localize.ja(ajv.errors);
    console.log(ajv.errorsText(ajv.errors, { separator: "\n" }));
    // console.log(ajv.errors);
  } else {
    console.log("No Error");
  }
};

const useStyles = makeStyles(styles);
// const ajv = new Ajv({ allErrors: true, verbose: true });
export default function Lesson3() {
  const classes = useStyles();
  const [obv2metadata, setOBV2Metadata] = useState({});
  const [obv2metadataAssertion, setOBV2MetadataAssertion] = useState({});
  const [obv2metadataBadgeClass, setOBV2MetadataBadgeClass] = useState({});
  const [obv2metadataIssuer, setOBV2MetadataIssuer] = useState({});
  const handleChange = (text) => {
    console.log("handleChange text: ",text);
    const datajson = JSON.parse(text);
    const Assertion = datajson.graph[1];
    const BadgeClass = datajson.graph[2];
    const Issuer = datajson.graph[3];
    setOBV2Metadata(datajson);
    setOBV2MetadataAssertion(Assertion);
    setOBV2MetadataBadgeClass(BadgeClass);
    setOBV2MetadataIssuer(Issuer);
     console.log("handleChange datajson: ",JSON.stringify(datajson,null,2));
     console.log("handleChange obv2metadataAssertion: ",JSON.stringify(obv2metadataAssertion,null,2));
     console.log("handleChange obv2metadataBadgeClass: ",JSON.stringify(obv2metadataBadgeClass,null,2));
     console.log("handleChange obv2metadataIssuer: ",JSON.stringify(obv2metadataIssuer,null,2));
  };
  const tenki = () => {
    console.log("tenki obv2metadata: ",obv2metadata);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>
              IMS Global オープンバッジ検証サイト 戻り値JSON入力域
            </h4>
            <p className={classes.cardCategoryWhite}>
              The results returned by the validator
            </p>
            <Button
              size="sm"
              color="primary"
              onClick={tenki}
            >
              TYPE別転記
            </Button>
          </CardHeader>
          <CardBody>

            <JSONEditorReact 
              json={obv2metadata}
              modes={modes}
            //   onChange={handleChange}
              onChangeText={handleChange}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Assertion.json : アサーション
            </h4>

            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Assertion",obv2metadataAssertion);
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={obv2metadataAssertion}
              modes={modes}
            //   onChange={handleChangeAssertion}
              ajv={ajv}
              schema={assertionSchema}
            />
          </CardBody>
        </Card>
      </GridItem>


      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 BadgeClass.json : バッジクラス
            </h4>

            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("BadgeClass",obv2metadataBadgeClass);
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={obv2metadataBadgeClass}
              modes={modes}
            //   onChange={handleChange}
              ajv={ajv}
              schema={BadgeClassSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Issuer.json : イシューア
            </h4>

            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Issuer",obv2metadataIssuer);
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={obv2metadataIssuer}
              modes={modes}
            //   onChange={handleChange}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
