import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import localize from "ajv-i18n";
import assertionSchema from "assets/openbadgeSchema/AssertionSchemaOBV2.json";
import issuerSchema from "assets/openbadgeSchema/IssuerSchemaOBV2.json";
import BadgeClassSchema from "assets/openbadgeSchema/BadgeClassSchemaOBV2.json";
// const assertionSchema = JSON.parse(assertionSchemaFile);
// console.log("assertionSchema: ", JSON.stringify(assertionSchema));
// console.log("issuerSchema: ", JSON.stringify(issuerSchema));
// console.log("BadgeClassSchema: ", JSON.stringify(BadgeClassSchema));
const ajv = new Ajv({ allErrors: true, verbose: true });
// ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-07.json"));
// const validate = ajv.getSchema("https://w3id.org/openbadges/v2");

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const IssuerData = {
  "@context": "https://w3id.org/openbadges/v2",
  type: "Issuer",
  id: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
  url: "https://imsjapan.org",
  email: "contactopenbadges@imsjapan.org",
  name: "IMS Japan Badge Issuer Demo"
};

const BadgeClassData = {
  "@context": "https://w3id.org/openbadges/v2",
  type: "BadgeClass",
  id:
    "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
  name: "IMS Japan Conference 2019 Demo Badge",
  description: "IMS Japan Conference 2019 Demo.",
  image: "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg",
  criteria:
    "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoCriteria.html",
  issuer: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json"
};

// const AssertionData = {
//   "@context": "https://w3id.org/openbadges/v2",
//   type: "Assertion",
//   id:
//     "https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.json",
//   recipient: {
//     type: "email",
//     hashed: true,
//     salt: "imsjapan",
//     identity:
//       "sha256$3e6a147d32ed200d557e53d15702145581962acc3d22e3777f7705066c7e2144"
//   },
//   badge:
//     "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
//   verification: {
//     type: "hosetd"
//   },
//   issuedOn: "2019-09-20T09:00:00Z"
// };

const AssertionData = {
  "@context": "https://w3id.org/openbadges/v2",
  type: "Assertion",
  id: "https://example.org/beths-robotics-badge.json",
  recipient: {
    type: "email",
    hashed: true,
    salt: "deadsea",
    identity:
      "sha256$c7ef86405ba71b85acd8e2e95166c4b111448089f2e1599f42fe1bba46e865c5"
  },
  issuedOn: "2016-12-31T23:59:59Z",
  badge: {
    id: "https://example.org/robotics-badge.json",
    type: "BadgeClass",
    name: "Awesome Robotics Badge",
    description:
      "For doing awesome things with robots that people think is pretty great.",
    image: "https://example.org/robotics-badge.png",
    criteria: "https://example.org/robotics-badge.html",
    issuer: {
      type: "Profile",
      id: "https://example.org/organization.json",
      name: "An Example Badge Issuer",
      image: "https://example.org/logo.png",
      url: "https://example.org",
      email: "steved@example.org"
    }
  },
  verification: {
    type: "hosted"
  }
};

const modes = ["tree", "code"];
// const modes = ["tree", "form", "view", "code", "text"];

const handleChange = () => {
  console.log("test");
};
const handleChangeAssertion = () => {
  console.log(JSON.stringify(AssertionData));
};

const jsonldValidate = schema => {
  console.log("schema: ", schema);
  var valid;
  switch (schema) {
    case "Issuer":
      valid = ajv.validate(issuerSchema, IssuerData);
      break;
    case "BadgeClass":
      valid = ajv.validate(BadgeClassSchema, BadgeClassData);
      break;
    case "Assertion":
      valid = ajv.validate(assertionSchema, AssertionData);
      break;
    default:
      break;
  }
  if (!valid) {
    localize.ja(ajv.errors);
    console.log(ajv.errorsText(ajv.errors, { separator: "\n" }));
    // console.log(ajv.errors);
  } else {
    console.log("No Error");
  }
};

const useStyles = makeStyles(styles);
// const ajv = new Ajv({ allErrors: true, verbose: true });
export default function Page2() {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Issuer.json
            </h4>
            <p className={classes.cardCategoryWhite}>
              Issuer : IMS Japan Badge Issuer Demo
            </p>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Issuer");
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={IssuerData}
              modes={modes}
              onChange={handleChange}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 BadgeClass.json
            </h4>
            <p className={classes.cardCategoryWhite}>
              BadgeClass : IMS Japan Badge BadgeClass Demo
            </p>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("BadgeClass");
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={BadgeClassData}
              modes={modes}
              onChange={handleChange}
              ajv={ajv}
              schema={BadgeClassSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Assertion.json
            </h4>
            <p className={classes.cardCategoryWhite}>
              Assertion : IMS Japan Badge Assertion Demo
            </p>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Assertion");
              }}
            >
              Validate
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={AssertionData}
              modes={modes}
              onChange={handleChangeAssertion}
              ajv={ajv}
              schema={assertionSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
