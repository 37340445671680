/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:b48b3cdd-1d98-409f-bae6-ee4307e98fae",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_1I4pbjWT0",
    "aws_user_pools_web_client_id": "5cfr53j3vd4mbgb943po27ksbu",
    "oauth": {},
    "aws_user_files_s3_bucket": "lptc-amplifystorage132826-test",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://g4jgl4urvjaebczkntesaix6jq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
