import React, {useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import localize from "ajv-i18n";
import issuerSchema from "assets/openbadgeSchema/IssuerSchemaOBV2.json";

const ajv = new Ajv({ allErrors: true, verbose: true });
const modes = [ "code", "tree"];
// const modes = ["tree", "form", "view", "code", "text"];

const handleChange = () => {
  console.log("test");
};

const IssuerData = {
  "@context": "https://w3id.org/openbadges/v2",
  type: "Issuer",
  id: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
  url: "https://imsjapan.org",
  email: "contactopenbadges@imsjapan.org",
  name: "IMS Japan Badge Issuer Demo"
};

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Lesson2() {
  const classes = useStyles();
  const [obv2metadata, setOBV2Metadata] = useState({});
  const handleChange = () => {
    setOBV2Metadata(obv2metadata);
  };
  return (
    // <GridContainer>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>レッスン 2</h4>
          </CardHeader>
          <CardBody>
            <Typography variant="headline" component="h3">
              サンプルバッジ: IMS Global Sample Open Badge (PNG)
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png
              </a>
            </Typography>
            <br />
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(PNG) ===
                https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(SVG) ===
                https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA"
                target="_blank"
                rel="noopener noreferrer"
              >
                オリジナルURL(JSON) ===
                https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA
              </a>
            </Typography>
            <br />
            <Typography variant="headline" component="h3">
              IMS Global オープンバッジ検証サイト
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              IMS Global Open Badges 2.0 Validator：
              <a
                href="https://openbadgesvalidator.imsglobal.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://openbadgesvalidator.imsglobal.org/
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              Expected Profile JSON (optional) 取得者のeメール : ' {"{"}
              "email":"conformance@imsglobal.org"{"}"} '
            </Typography>
            <br />
            <Typography variant="headline" component="h3">
              SHA256ハッシュ生成ツール:
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="https://www.convertstring.com/ja/Hash/SHA256"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.convertstring.com/ja/Hash/SHA256
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              暗号塩："salt": "ec9d81269505419681e6c3f6853679c4"
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              "email":"conformance@imsglobal.org"
            </Typography>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>
            IMS Global オープンバッジ検証サイト 戻り値JSON入力域
            </h4>
            <p className={classes.cardCategoryWhite}>
            The results returned by the validator
            </p>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={obv2metadata}
              modes={modes}
              onChange={handleChange}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    // </GridContainer>
  );
}
