/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
// import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

import Amplify, { I18n } from "aws-amplify";
import { messages } from "./assets/i18n/amplify/messages";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifyConfirmSignUp,
  AmplifySignIn
} from "@aws-amplify/ui-react";
import aws_exports from "./aws-exports";
import "@aws-amplify/ui/dist/style.css";
Amplify.configure(aws_exports);
I18n.putVocabularies(messages);
I18n.setLanguage("ja");
const hist = createBrowserHistory();

// const contentStyle = {
//   paddingLeft: "0",
//   paddingRight: "0",
//   textAlign: "center",
// };
// const myAmplifySignUp = (
//   <div style={contentStyle}>
//     <AmplifyAuthenticator />
//   </div>
// );

ReactDOM.render(
  <AmplifyAuthenticator>
    <div slot="sign-in" style={{ textAlign: "center" }}>
      <AmplifySignIn headerText="オープンバッジ基本コンセプト・ハンズオン・サインイン画面" />
    </div>
    <div slot="sign-up" style={{ textAlign: "center" }}>
      <AmplifySignUp
        slot="sign-up"
        // usernameAlias="email"
        formFields={[
          {
            type: "username",
            // label: "Custom username Label",
            // placeholder: "custom username placeholder",
            required: true
          },
          {
            type: "email",
            // label: "Custom email Label",
            // placeholder: "custom email placeholder",
            required: true
          },
          {
            type: "password",
            // label: "Custom Password Label",
            // placeholder: "custom password placeholder",
            required: true
          },
          // {
          //   type: "phone_number",
          //   label: "Custom Phone Label",
          //   placeholder: "custom Phone placeholder",
          //   required: false,
          // },
        ]}
      />
    </div>
    {/* <div slot="confirm-sign-up" style={{ textAlign: "center" }}>
      <AmplifyConfirmSignUp/>
    </div> */}
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={Admin} />
        {/* <Route path="/rtl" component={RTL} /> */}
        <Redirect from="/" to="/admin/demoinfo" />
      </Switch>
    </Router>
  </AmplifyAuthenticator>,
  document.getElementById("root")
);
