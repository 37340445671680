import React, { useState, useEffect, useCallback, useReducer } from "react";
import Amplify, { Auth, Storage } from "aws-amplify";
import { PhotoPicker, S3Album, S3Image, TextPicker } from "aws-amplify-react";

// Amplify のPhotoPickerを使用して、png fileを読込 chunk iTxtのOpen Badge Assertion JSON を取得するために必要なnpm module
import pngitxt from "png-itxt";
import toStream from "buffer-to-stream";
import dataUriToBuffer from "data-uri-to-buffer";
import stream from "stream";
import request from "request";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import InputLabel from "@material-ui/core/InputLabel";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true, verbose: true });
const validate = ajv.getSchema("https://w3id.org/openbadges/v2");
const modes = ["tree", "code"];

//--------------------------------
//Badge Image Folder名
const badgeimagefolder = "badgeimage/";
//Badge Image Folder名
const badgeimageEditfolder = "badgeimage-edit/";
//Badge JSON Folder名
const badgejsonfolder = "badgejson/";
let jsondata;
//PNG画像ファイルにiTxtを書き込むために、pngitxtで使用するstreamからpipe経由で書き込むための方法
var segments = [];
var fileinfo;
var writable = new stream.Writable({
  write: function(chunk, encoding, next) {
    // console.log(chunk);
    // console.log("chunk.byteLength: ", chunk.byteLength);
    segments.push(chunk);
    next();
  }
});
writable.on("finish", () => {
  console.log("finish arrayBuffer");
  var sumLength = 0;
  for (var i = 0; i < segments.length; ++i) {
    sumLength += segments[i].byteLength;
  }
  var whole = new Uint8Array(sumLength);
  var pos = 0;
  for (i = 0; i < segments.length; ++i) {
    whole.set(new Uint8Array(segments[i]), pos);
    pos += segments[i].byteLength;
  }
  console.log(whole);
  //byteArrayをBlobに変換してStorage.putでS3に保存
  var blob = new Blob([whole]);
  const newfilename =
    fileinfo.name.split(".")[0] + "-edit." + fileinfo.name.split(".")[1];
  console.log("newfilename: ", newfilename);
  Storage.put(badgeimageEditfolder + newfilename, blob, {
    level: "private",
    contentType: fileinfo.type
  })
    .then(result => console.log(result))
    .catch(err => console.log(err));
  const newjsonfilename = fileinfo.name.split(".")[0] + "-edit.json";
  console.log("newjsonfilename: ", newjsonfilename);
  Storage.put(badgejsonfolder + newjsonfilename, jsondata, {
    level: "protected",
    // contentType: 'text/json'
    contentType: "application/json"
  })
    .then(result => console.log(result))
    .catch(err => console.log(err));
});

// function callbackPng(err, data) {
//   console.log("pngitxt.get");
//   if (!err && data) {
//     console.log("openbadges True");
//     // console.log(JSON.stringify(data));
//     const { value } = data;
//     const objsondata = JSON.parse(value);
//     console.log("openbadges objsondata type : ", objsondata.type);
//     setOBV2Metadata();
//     console.log(JSON.stringify(objsondata, null, "\t"));
//   } else {
//     console.log("openbadges False", err);
//   }
// }

const initialBadgeList = [];
const reducerListFile = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

export default function LaunchBadges() {
  const classes = useStyles();
  const [pickfilekey, setPickFileKey] = useState();
  const [obv2metadata, setOBV2Metadata] = useState({});
  // const [jeditorkey, setJsonEditorKey] = useState(1);
  // const { stateJson, onChangeJson } = useJson();
  const [listfiles, dispatchStorageList] = useReducer(
    reducerListFile,
    initialBadgeList
  );

  function callbackPng(err, data) {
    console.log("pngitxt.get");
    if (!err && data) {
      console.log("openbadges True");
      // console.log(JSON.stringify(data));
      const { value } = data;
      const objsondata = JSON.parse(value);
      console.log("openbadges objsondata type : ", objsondata.type);
      setOBV2Metadata(objsondata);
      console.log(JSON.stringify(objsondata, null, "\t"));
    } else {
      console.log("openbadges False", err);
    }
  }

  const onSetPickFileKey = useCallback(key => {
    setPickFileKey(prev => key);
  }, []);

  const onSetListFiles = () => {
    // Using an IIFE
    (async function anyNameFunction() {
      const listfilesall = await Storage.list(badgeimagefolder, {
        level: "private"
      });
      console.log(listfilesall);
      dispatchStorageList({ type: "FETCH_SUCCESS", payload: listfilesall });
    })();
  };
  useEffect(() => {
    onSetListFiles();
  }, []);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>表示Display Badge Info</h4>
      </CardHeader>

      <CardBody>
        <GridContainer>
          {listfiles.map((f, i) => {
            console.log("listfiles.map: ", i, f.key);
            return (
              <GridItem
                xs={12}
                sm={6}
                md={3}
                // className={classes.root}
                key={i}
                // style={{ height: 80, width: 80 }}
              >
                <Card>
                  <CardActionArea>
                    <S3Image
                      theme={{
                        photoImg: {
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "50%"

                          // hover: {
                          //   WebkitFilter: "grayscale(100%);",
                          //   filter: "grayscale(100%)"
                          // }
                        }
                      }}
                      // style={{ display: "inline-block", paddingRight: "5px" }}
                      level="private"
                      imgKey={f.key}
                      onClick={() => {
                        console.log(f.key);
                        onSetPickFileKey(f.key);
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Lizard
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="sm" color="primary">
                      Share
                    </Button>
                    <Button size="sm" color="primary">
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </CardBody>
    </Card>
  );
}
