export const messages = {
  ja: {
    "Email Address *": "メールアドレス *",
    "Enter your email address": "メールアドレス",
    "Sign In": "サインイン",
    "Sign Up": "サインアップ",
    "Sign Out": "サインアウト",
    "Sign in to your account": "アカウントにサインイン",
    "Username *": "ユーザー名 *",
    "Password *": "パスワード *",
    "Enter your username": "ユーザー名を入力",
    "Enter your password": "パスワードを入力",
    "No account?": "アカウントが未登録ですか？",
    "Forgot your password?": "パスワードをお忘れですか？",
    "Reset password": "パスワードをリセット",
    "User does not exist": "ユーザーが存在しません",
    "User already exists": "ユーザーは既に存在します",
    "Incorrect username or password.": "ユーザー名またはパスワードが違います",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Create account": "アカウントを作成",
    "Forgot Password": "パスワードを忘れた",
    "Change Password": "パスワードを変更",
    "New Password": "新しいパスワード",
    "Email": "Email",
    "Phone Number": "電話番号",
    "Confirm a Code": "コードを確認",
    "Confirm Sign In": "サインインを確認",
    "Confirm Sign up": "サインアップを確認",
    "Back to Sign In": "サインインに戻る",
    "Send Code": "コードを送信",
    "Confirm": "確認",
    "Resend Code": "コードを再送",
    "Submit": "送信",
    "Skip": "スキップ",
    "Verify": "検証",
    "Verify Contact": "連絡先を検証",
    "Code": "Code",
    "Confirmation Code": "確認コード",
    "Lost your code?": "コードがありませんか？",
    "Account recovery requires verified contact information":
      "Account recovery requires verified contact information",
    "Invalid phone number format":
      "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "Create Account": "アカウントを作成",
    "Have an account?": "アカウントをお持ちですか？",
    "Sign in": "サインイン___",
    "Create a new account": "新しいアカウントを作成",
    "Reset your password": "パスワードをリセット",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Username cannot be empty": "ユーザー名は必須です",
    "Password attempts exceeded": "パスワード試行回数が超過しました"
    // "Back to Sign In": "サインイン画面に戻る",
    // Confirm: "確認",
    // "Confirm Sign Up": "サインアップの確認",
    // "Confirmation Code": "確認コード",
    // "Create Account": "新規登録",
    // "Create a new account": "アカウントの新規登録",
    // "Create account": "新規登録",
    // Email: "メールアドレス",
    // "Enter your code": "確認コードを入力してください",
    // "Enter your password": "パスワードを入力してください",
    // "Enter your username": "ユーザー名を入力してください",
    // "Forget your password? ": "パスワードをお忘れの方 ",
    // "Have an account? ": "アカウント登録済みの方 ",
    // Hello: "こんにちは ",
    // "Incorrect username or password": "ユーザー名またはパスワードが異なります",
    // "Lost your code? ": "コードを紛失した方 ",
    // "No account? ": "アカウント未登録の方 ",
    // Password: "パスワード",
    // "Phone Number": "電話番号",
    // "Resend Code": "確認コードの再送",
    // "Reset password": "パスワードのリセット",
    // "Reset your password": "パスワードのリセット",
    // "Send Code": "コードの送信",
    // "Sign In": "サインイン",
    // "Sign Out": "サインアウト",
    // "Sign in": "サインイン",
    // "Sign in to your account": "サインイン",
    // "User does not exist": "ユーザーが存在しません",
    // Username: "ユーザー名",
    // "Username cannot be empty": "ユーザー名は必須入力です",
    // "Username/client id combination not found.": "ユーザー名が見つかりません",
  }
};
