/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
import Page1 from "views/Page1/Page1.js";
import Page2 from "views/Page2/Page2.js";
import Page3 from "views/Page3/Page3.js";
import Page4 from "views/Page4/Page4.js";
import Page5 from "views/Page5/Page5.js";
import Page6 from "views/Page6/Page6.js";
import Page7 from "views/Page7/Page7.js";
import Page8 from "views/Page8/Page8.js";
import LaunchBadges from "views/LaunchBadges/LaunchBadges.js";

import Demoinfo from "views/Demoinfo/Demoinfo.js";
import Lesson1 from "views/Lesson1/Lesson1.js"
import Lesson2 from "views/Lesson2/Lesson2.js"
import Lesson3 from "views/Lesson3/Lesson3.js"
import Lesson4 from "views/Lesson4/Lesson4.js"
import Lesson5 from "views/Lesson5/Lesson5.js"
import Lesson6 from "views/Lesson6/Lesson6.js"

const dashboardRoutes = [
  {
    path: "/demoinfo",
    name: "ハンズオン資料",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Demoinfo,
    layout: "/admin"
  },
  {
    path: "/lesson1",
    name: "レッスン 1",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Lesson1,
    layout: "/admin"
  },
  {
    path: "/lesson2",
    name: "レッスン 2",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Lesson2,
    layout: "/admin"
  },
  {
    path: "/lesson3",
    name: "レッスン 3",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Lesson3,
    layout: "/admin"
  },
  {
    path: "/lesson4",
    name: "レッスン 4",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Lesson4,
    layout: "/admin"
  },
  {
    path: "/lesson5",
    name: "レッスン 5",
    // rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Lesson5,
    layout: "/admin"
  },
  // {
  //   path: "/lesson6",
  //   name: "レッスン 6",
  //   // rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: Lesson6,
  //   layout: "/admin"
  // },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   // rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/launchBadges",
  //   name: "Learn React",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: LaunchBadges,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page1",
  //   name: "Page1 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page1,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page2",
  //   name: "Page2 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page2,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page3",
  //   name: "Page3 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page3,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page4",
  //   name: "Page4 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page4,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page5",
  //   name: "Page5 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page5,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page6",
  //   name: "Page6 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page6,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page7",
  //   name: "Page7 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page7,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page8",
  //   name: "Page8 name",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Page8,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   // rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   // rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   // rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   // rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   // rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   // rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
];

export default dashboardRoutes;
