import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import Input from '@material-ui/core/Input';
// import FormData from 'form-data';

// import { API } from 'aws-amplify';

import JSONEditorReact from "components/JsonEditorReact/JSONEditorReact";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import localize from "ajv-i18n";
import assertionSchema from "assets/openbadgeSchema/AssertionSchemaOBV2.json";
import issuerSchema from "assets/openbadgeSchema/IssuerSchemaOBV2.json";
import BadgeClassSchema from "assets/openbadgeSchema/BadgeClassSchemaOBV2.json";
const ajv = new Ajv({ allErrors: true, verbose: true });

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  }
};

const fileUpload = async (uploadPath, uploadfile) => {
    const formData = new FormData();
    formData.append('body-json', uploadfile);
    formData.append('assertion', "https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A");
    formData.append('recipient_profile', "{\"email\": \"t-hata@digital-knowledge.co.jp\"}");
    const headers = {
         "content-type": "multipart/form-data"
        };
    return await axios.post(uploadPath, formData,{headers});
    // return await axios.post(uploadPath, {
    //     assertion: 'https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A',
    //     recipient_profile: '{\"email\": \"t-hata@digital-knowledge.co.jp\"}'
    //   },{headers});
};

const useStyles = makeStyles(styles);

const modes = [ "code", "tree", "form"];
const jsonldValidate = (schema, jsondata) => {
    console.log("schema: ", schema);
    var valid;
    switch (schema) {
      case "Issuer":
        valid = ajv.validate(issuerSchema, jsondata);
        break;
      case "BadgeClass":
        valid = ajv.validate(BadgeClassSchema, jsondata);
        break;
      case "Assertion":
        valid = ajv.validate(assertionSchema, jsondata);
        break;
      default:
        break;
    }
    if (!valid) {
      localize.ja(ajv.errors);
      console.log(ajv.errorsText(ajv.errors, { separator: "\n" }));
      // console.log(ajv.errors);
    } else {
      console.log("No Error");
    }
  };

  const IssuerData = {
    "@context": "https://w3id.org/openbadges/v2",
    type: "Issuer",
    id: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
    url: "https://imsjapan.org",
    email: "contactopenbadges@imsjapan.org",
    name: "IMS Japan Badge Issuer Demo"
  };
  
  const BadgeClassData = {
    "@context": "https://w3id.org/openbadges/v2",
    type: "BadgeClass",
    id:
      "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
    name: "IMS Japan Conference 2019 Demo Badge",
    description: "IMS Japan Conference 2019 Demo.",
    image: "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg",
    criteria:
      "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoCriteria.html",
    issuer: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json"
  };
  
  const AssertionData = {
    "@context": "https://w3id.org/openbadges/v2",
    type: "Assertion",
    id:
      "https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.json",
    recipient: {
      type: "email",
      hashed: true,
      salt: "imsjapan",
      identity:
        "sha256$3e6a147d32ed200d557e53d15702145581962acc3d22e3777f7705066c7e2144"
    },
    badge:
      "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
    verification: {
      type: "hosetd"
    },
    issuedOn: "2019-09-20T09:00:00Z"
  };
  
//   const AssertionData = {
//     "@context": "https://w3id.org/openbadges/v2",
//     type: "Assertion",
//     id: "https://example.org/beths-robotics-badge.json",
//     recipient: {
//       type: "email",
//       hashed: true,
//       salt: "deadsea",
//       identity:
//         "sha256$c7ef86405ba71b85acd8e2e95166c4b111448089f2e1599f42fe1bba46e865c5"
//     },
//     issuedOn: "2016-12-31T23:59:59Z",
//     badge: {
//       id: "https://example.org/robotics-badge.json",
//       type: "BadgeClass",
//       name: "Awesome Robotics Badge",
//       description:
//         "For doing awesome things with robots that people think is pretty great.",
//       image: "https://example.org/robotics-badge.png",
//       criteria: "https://example.org/robotics-badge.html",
//       issuer: {
//         type: "Profile",
//         id: "https://example.org/organization.json",
//         name: "An Example Badge Issuer",
//         image: "https://example.org/logo.png",
//         url: "https://example.org",
//         email: "steved@example.org"
//       }
//     },
//     verification: {
//       type: "hosted"
//     }
//   };

export default function Lesson4() {
  const classes = useStyles();

  const [obv2metadata, setOBV2Metadata] = useState({});
  const [AssertionJson, setAssertionJson] = useState(AssertionData);
  const [BadgeClassJson, setBadgeClassJson] = useState(BadgeClassData);
  const [IssuerJson, setIssuerJson] = useState(IssuerData);
  const [emailValue,   setInput]   = useState('');
  const [files, setFiles] = useState([]);


//   const [obv2metadataAssertion, setOBV2MetadataAssertion] = useState({});
//   const [obv2metadataBadgeClass, setOBV2MetadataBadgeClass] = useState({});
//   const [obv2metadataIssuer, setOBV2MetadataIssuer] = useState({});

  const handleChange = async (uploadfile,emailValue) => {
    console.log("handleChange");
    const uploadApiPath = "https://2nx9i2outa.execute-api.us-east-1.amazonaws.com/default/uploadMultiData";
    // const uploadApiPath = "https://openbadgesvalidator.imsglobal.org/results";
    const formData = new FormData();
    formData.append('body-json', uploadfile);
    // formData.append('assertion', "https://api.badgr.io/public/assertions/Prux0NcITp2Dq3TpgaV3_A");
    // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
    // formData.append('recipient_profile', '{\"email\": \"t-hata@digital-knowledge.co.jp\"}');
    console.log("email: "+ emailValue);
    // const params = `{'recipient_profile': '{"email": "${emailValue}"}'}`;
    const params = JSON.parse(`{"recipient_profile": {"email": "${emailValue}"}}`);
    // const params = {'recipient_profile': '{"email": "t-hata@digital-knowledge.co.jp"}'};
    console.log("params: "+ JSON.stringify(params));
    const headers = {
         "content-type": "multipart/form-data"
        };
    const returnMessage =  await axios.post(uploadApiPath, formData,{headers,params});
    // console.log("returnMessage: ",returnMessage);
    // console.log("returnMessage: ",JSON.stringify(returnMessage));
    const { data } = returnMessage;
    if (JSON.stringify(data).indexOf("errorMessage") > -1 &&  JSON.stringify(data).indexOf("errorType") > -1 ) {
      console.log("Error: errorMessage , errorType ==> ", data["errorMessage"] + " : " + data["errorType"]);
      return
    }
    const { is_valid } = data;
    const { body } = data;
    const resultJson = JSON.parse(body);
    const { graph } = resultJson;
    const { input } = resultJson;
    const { report } = resultJson;
    console.log("is_valid: ",is_valid);
    setOBV2Metadata(resultJson);
    // console.log("graph: ",graph);

    var Assertion,BadgeClass,Issuer
    graph.forEach(function(item,index){
      console.log(index + " : " + item["type"])
      console.log(`Badge Json: ${item["type"]}.`);
      switch (item["type"]) {
        case 'Assertion':
          Assertion = item;
          setAssertionJson(Assertion);
          break;
        case 'BadgeClass':
          BadgeClass = item;
          setBadgeClassJson(BadgeClass);
          break;
        case 'Issuer':
          Issuer = item;
          setIssuerJson(Issuer);
          break;
        default:
          console.log(`No Badge Json`);
      }
    })

    console.log("Assertion: ",JSON.stringify(Assertion,null,2));
    console.log("BadgeClass: ",JSON.stringify(BadgeClass,null,2));
    console.log("Issuer: ",JSON.stringify(Issuer,null,2));
    console.log("input: ",JSON.stringify(input,null,2));
    console.log("report: ",JSON.stringify(report,null,2));
    // console.log("Issuer: ",graph[2]);

  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, .json',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  const handleChangeText = (e) => {
    console.log("handleChangeText:",e.target.value)
    setInput(e.target.value);
  };
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleChangeJsonEditor = () => {
    console.log("test");
  };

  return (
    // <GridContainer>
    <GridContainer>
         <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>レッスン 4</h4>
          </CardHeader>
          <CardBody>
            <Typography variant="headline" component="h3">
              サンプルバッジ: IMS Japan Demo (PNG)
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a
                href="http://s3.aws.imsjapan.org/demo_imsjapan.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://s3.aws.imsjapan.org/demo_imsjapan.png
              </a>
            </Typography>
            <br />

            </CardBody>
        </Card>
      </GridItem>






      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Assertion.json : アサーション
            </h4>

            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Assertion",AssertionJson);
              }}
            >
              Save
            </Button>
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={AssertionJson}
              modes={modes}
            //   onChange={handleChangeAssertion}
              ajv={ajv}
              schema={assertionSchema}
            />
          </CardBody>
        </Card>
      </GridItem>


      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 BadgeClass.json : バッジクラス
            </h4>

            {/* <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("BadgeClass",BadgeClassJson);
              }}
            >
              Validate
            </Button> */}
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={BadgeClassJson}
              modes={modes}
            //   onChange={handleChange}
              ajv={ajv}
              schema={BadgeClassSchema}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>
              Open Badges 2.0 Issuer.json : イシューア
            </h4>
{/* 
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                jsonldValidate("Issuer",IssuerJson);
              }}
            >
              Validate
            </Button> */}
          </CardHeader>
          <CardBody>
            <JSONEditorReact
              json={IssuerJson}
              modes={modes}
            //   onChange={handleChange}
              ajv={ajv}
              schema={issuerSchema}
            />
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
    // </GridContainer>
  );
}
