import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Lesson1() {
  const classes = useStyles();
  return (
    // <GridContainer>
    //   <GridContainer>
    //   <GridItem >
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>レッスン 1</h4>
      </CardHeader>
      <CardBody>
        <Typography variant="headline" component="h3">
          サンプルバッジ: IMS Global Sample Open Badge (PNG)
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          <a
            href="http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://s3.aws.imsjapan.org/OB20-assertion1-conformance.png
          </a>
        </Typography>
        <br />
        <Typography component="p" className={classes.paragraph}>
        オリジナルURL(PNG) ===
          <a
            href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            
            https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion1-conformance.png
          </a>
        </Typography>
        <Typography component="p" className={classes.paragraph}>
        オリジナルURL(SVG) ===
          <a
            href="https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg"
            target="_blank"
            rel="noopener noreferrer"
          >
            
            https://openbadgesvalidator.imsglobal.org/SampleResources/OB20-assertion-conformance-servicelearning.svg
          </a>
        </Typography>
        <Typography component="p" className={classes.paragraph}>
        オリジナルURL(JSON) ===
          <a
            href="https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA.json"
            target="_blank"
            rel="noopener noreferrer"
          >
           
            https://badges.imsglobal.org/public/assertions/1geQXkWnQnW0BkWcO3jSPA.json
          </a>
        </Typography>
        <br />
        <Typography variant="headline" component="h3">
          PNG画像解析ツール: TweakPNG
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          TweakPNG：
          <a
            href="http://entropymine.com/jason/tweakpng/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TweakPNG
          </a>
        </Typography>
        <br />

        <Typography variant="headline" component="h3">
          PNG解析サイト: png identify
        </Typography>
        <Typography component="p" className={classes.paragraph}>
        PNG解析サイト: 
          <a
            href="http://imaya.github.com/png.identify/"
            target="_blank"
            rel="noopener noreferrer"
          >
            png identify
          </a>
        </Typography>
      </CardBody>
    </Card>
    //     </GridItem>
    //   </GridContainer>
    // </GridContainer>
  );
}
